import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import colors from "./colors";
import AppleSdNeoRegular from "./fonts/AppleSDGothicNeoR.ttf";
import AppleSdNeoMedium from "./fonts/AppleSDGothicNeoM.ttf";
import AppleSdNeoBold from "./fonts/AppleSDGothicNeoB.ttf";

export default createGlobalStyle`
  ${reset};

  @font-face {
    font-family: 'AppleSdNeoRegular';
    src: url(${AppleSdNeoRegular});
  }
  @font-face {
    font-family: 'AppleSdNeoMedium';
    src: url(${AppleSdNeoMedium});
  }
  @font-face {
    font-family: 'AppleSdNeoBold';
    src: url(${AppleSdNeoBold});
  }

  * {
    box-sizing: border-box;
    
    /* scroll-behavior: smooth; */
  }

  body {
    width: 100%;
    min-width: 1144px;
    font-family: 'AppleSdNeoBold';
    font-size: 15px;
    /* font-family:'Apple SD Gothic Neo', sans-serif; */
    overflow: scroll;
    box-sizing: border-box;
    scrollbar-width: none;
    -ms-overflow-style: none;
  
  }

  a {
    color: ${colors.blackColor};
    text-decoration: none;
  }

  textarea {
    resize: none;
  }

  input,
  textarea {
    border: none;
    outline: none;

    &::placeholder {
      font-size: 15px !important;
      font-family: 'AppleSdNeoRegular' !important;
    }
  }
  
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }

  button {
    cursor: pointer;
    outline: none;
    border: none;
    background-color: ${colors.whiteColor};
  }

  select {
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
  }
  li {
    list-style: none;
  }
  ul{
    list-style: none;
  }
  div{
    margin:0;
    padding: 0;
  }
`;
