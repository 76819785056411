import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import EstimatePresenter from "./EstimatePresenter";
import hippocratesIcon from "../../assets/icons/hippocratesIcon.svg";
import hippocratesCheckIcon from "../../assets/icons/hippocratesCheckIcon.svg";
import hippocratesImageIcon from "../../assets/icons/hippocratesImageIcon.svg";
import hippocratesLABIcon from "../../assets/icons/hippocratesLABIcon.svg";
import hippocratesEsignIcon from "../../assets/icons/hippocratesEsignIcon.svg";
import hippocratesDeskIcon from "../../assets/icons/hippocratesDeskIcon.svg";
import hippocratesCloudIcon from "../../assets/icons/hippocratesCloudIcon.svg";
import hippocratesDIDIcon from "../../assets/icons/hippocratesDIDIcon.svg";
import hippocratesCheckupIcon from "../../assets/icons/hippocratesCheckupIcon.svg";
import hippocratesPenChartIcon from "../../assets/icons/hippocratesPenChartIcon.svg";
import laonzenaIcon from "../../assets/icons/laonzenaIcon.svg";
import etcIcon from "../../assets/icons/etcIcon.svg";
import { useMutation } from "@apollo/client";
import { CREATE_ESTIMATE_INQUIRY } from "../../graphql/Estimate/mutation";

const emrChartList = [
  { title: "Hippocrates", src: hippocratesIcon },
  { title: "Hippocrates Check", src: hippocratesCheckIcon },
  { title: "Hippocrates Image", src: hippocratesImageIcon },
  { title: "Hippocrates LAB", src: hippocratesLABIcon },
  { title: "Hippocrates e-Sign", src: hippocratesEsignIcon },
  { title: "Hippocrates Desk", src: hippocratesDeskIcon },
  { title: "Hippocrates Cloud Service", src: hippocratesCloudIcon },
  { title: "Hippocrates Web DID", src: hippocratesDIDIcon },
  {
    title: "Hippocrates Medical Checkup(건강검진)",
    src: hippocratesCheckupIcon,
  },
  { title: "Hippocrates Pen Chart", src: hippocratesPenChartIcon },
  { title: "라온제나 CRM", src: laonzenaIcon },
  { title: "기타서비스", src: etcIcon },
];
const medicalImagingEquipmentList = [
  "R-DR (Digital Radiography)",
  "CR (Computed Radiography)",
  "X-Ray System",
  "C-Arm",
  "Mammography (DR/CR)",
  "초음파",
  "내시경",
  "CT",
  "MRI",
];
const generalInspectionEquipmentList = [
  "동맥경화 검사기",
  "폐기능 검사기(Spirometer)",
  "ECG (Cardionet-S)",
];
const medicalImageProcessingUnitList = ["PACS System", "원격영상판독"];

const EstimateContainer = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    medicalDepartment: "",
    usePCCount: "",
    medicalImagingEquipment: [],
    generalInspectionEquipment: [],
    medicalImageProcessingUnit: [],
    name: "",
    hospitalName: "",
    region: "",
    tel: "",
    email: "",
    message: "",
  });
  const [agree, setAgree] = useState([]);

  const onChangeInputs = useCallback(
    (e) => {
      const { name, value } = e.target;
      setInputs({
        ...inputs,
        [name]: value,
      });
    },
    [inputs]
  );

  // 체크박스 선택
  const handleChecklist = useCallback(
    (name, value, isChecked) => {
      if (isChecked) {
        setInputs((prev) => ({
          ...prev,
          [name]: [...prev[name], value],
        }));
        return;
      }

      if (!isChecked && inputs[name].includes(value)) {
        setInputs((prev) => ({
          ...prev,
          [name]: prev[name].filter((item) => item !== value),
        }));
      }
    },
    [inputs]
  );
  // 동의하기
  const handleAgreeCheck = useCallback(
    (_, value, isChecked) => {
      if (isChecked) {
        setAgree((prev) => [...prev, value]);
        return;
      }

      if (!isChecked && agree.includes(value)) {
        setAgree((prev) => prev.filter((item) => item !== value));
      }
    },
    [agree]
  );

  const [createEstimate] = useMutation(CREATE_ESTIMATE_INQUIRY);
  
  const handleContact = useCallback(async (e) => {
    const {
      medicalDepartment,
      usePCCount,
      name,
      hospitalName,
      region,
      tel,
      email,
      message,
    } = inputs;

    if (
      medicalDepartment === "" ||
      usePCCount === "" ||
      name === "" ||
      hospitalName === "" ||
      region === "" ||
      tel === "" ||
      email === ""
    ) {
      alert("필수 항목을 모두 입력해주세요.");
      return;
    }

    if(agree.length !== 2){
      alert("개인정보 수집 및 유의사항에 동의해주세요.");
      return;
    }
   
    try {
      const { data } = await createEstimate({
        variables: {
          eiMedicalSub: medicalDepartment,
          eiPcCount: usePCCount,
          eiRdr: inputs.medicalImagingEquipment.includes(
            "R-DR (Digital Radiography)"
          ),
          eiCr: inputs.medicalImagingEquipment.includes(
            "CR (Computed Radiography)"
          ),
          eiXray: inputs.medicalImagingEquipment.includes("X-Ray System"),
          eiCArm: inputs.medicalImagingEquipment.includes("C-Arm"),
          eiMammography: inputs.medicalImagingEquipment.includes(
            "Mammography (DR/CR)"
          ),
          eiUltrasonicWave: inputs.medicalImagingEquipment.includes("초음파"),
          eiEndoscope: inputs.medicalImagingEquipment.includes("내시경"),
          eiCt: inputs.medicalImagingEquipment.includes("CT"),
          eiMri: inputs.medicalImagingEquipment.includes("MRI"),
          eiArteriosclerosis:
            inputs.generalInspectionEquipment.includes("동맥경화 검사기"),
          eiSpirometer: inputs.generalInspectionEquipment.includes(
            "폐기능 검사기(Spirometer)"
          ),
          eiEcg:
            inputs.generalInspectionEquipment.includes("ECG (Cardionet-S)"),
          eiPacs: inputs.medicalImageProcessingUnit.includes("PACS System"),
          eiRemoteImageReading:
            inputs.medicalImageProcessingUnit.includes("원격영상판독"),
          eiName: name,
          eiHospitalName: hospitalName,
          eiWorkArea: region,
          eiCellphone: tel,
          eiEmail: email,
          eiEtc: message,
        },
      });
    
  
      if (data.createEstimateInquiry) {
        alert("온라인 견적 문의 등록되었습니다.");
        setInputs({
          medicalDepartment: "",
          usePCCount: "",
          medicalImagingEquipment: [],
          generalInspectionEquipment: [],
          medicalImageProcessingUnit: [],
          name: "",
          hospitalName: "",
          region: "",
          tel: "",
          email: "",
          message: "",
        });
        setAgree([]); 
      }
    } catch (e) {}
  }, [inputs, agree]);

  const handleCancel = useCallback(
    (e) => {
      if (window.confirm("정말로 취소하시겠습니까?")) {
        const { name } = e.target;
        const value = '';
        setInputs({
          ...inputs,
          [name]: value,
        });
        setAgree([]); 
      } else {
        return;
      }
    },
    []
  );

  

  return (
    <EstimatePresenter
      emrChartList={emrChartList}
      medicalImagingEquipmentList={medicalImagingEquipmentList}
      generalInspectionEquipmentList={generalInspectionEquipmentList}
      medicalImageProcessingUnitList={medicalImageProcessingUnitList}
      inputs={inputs}
      onChangeInputs={onChangeInputs}
      handleChecklist={handleChecklist}
      agree={agree}
      handleAgreeCheck={handleAgreeCheck}
      handleContact={handleContact}
      handleCancel={handleCancel}
    />
  );
};

export default EstimateContainer;
