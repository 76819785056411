import styled from 'styled-components';
import PageIntro from '../../components/layout/PageIntro';
import colors from '../../styles/colors';
import EMRChart from '../../components/feature/Estimate/EMRChart';
import BrochureDownload from '../../components/share/BrochureDownload';
import StyledInput from '../../components/share/StyledInput';
import StyledCheckbox from '../../components/share/StyledCheckbox';
import AgreeBox from '../../components/share/AgreeBox';
import StyledButton from '../../components/share/StyledButton';

const ChartBox = styled.div`
  width: 100%;
  padding: 40px 68px 82px;
  background-color: ${colors.lightGrayBorder};
`;
const FlexBox = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'space-between')};
  margin: ${({ margin }) => (margin ? margin : 0)};
`;
const ChartTitleBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;
const TitleDOM = styled.div`
  width: 1144px;
  max-width: 1144px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ContextBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
const GirdBox = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(6, 1fr);
  max-width: 1144px;
`;
const BoldText = styled.span`
  font-size: 18px;
`;
const Container = styled.section`
  max-width: 1144px;
  display: flex;
  justify-content: space-between;
  margin: 80px auto 40px;
`;
const InsertBox = styled.div`
  width: calc(100% - 250px);
  border-top: 2px solid ${colors.blackColor};
`;

const EstimatePresenter = ({
  emrChartList,
  medicalImagingEquipmentList,
  generalInspectionEquipmentList,
  medicalImageProcessingUnitList,
  inputs,
  onChangeInputs,
  handleChecklist,
  agree,
  handleAgreeCheck,
  handleContact,
  handleCancel,
}) => {
  return (
    <>
      <PageIntro
        guideLine={['홈', '온라인 견적']}
        title={`온라인 견적 문의를 주시면\n최대한 빠르게 답변드리겠습니다.`}
        subTitle={`온라인 견적 문의는 본 시스템을 통해 접수된 건에 한하여 정식으로\n검토되며 최대한 빠르게 기재해주신 연락처로 답변드리고 있습니다.`}
      />
      <ChartBox>
        <ChartTitleBox>
          <TitleDOM>
            <BoldText>EMR 차트</BoldText>
            <BrochureDownload margin={0} onClick={() => console.info('다운로드')} />
          </TitleDOM>
        </ChartTitleBox>
        <ContextBox>
          <GirdBox>
            {emrChartList.map((list, index) => (
              <EMRChart key={`${index}-list`} title={list.title} src={list.src} />
            ))}
          </GirdBox>
        </ContextBox>
      </ChartBox>

      <Container>
        <BoldText>온라인 견적 작성</BoldText>
        <InsertBox>
          <StyledInput
            REQUIRED
            label="진료과 입력"
            name="medicalDepartment"
            value={inputs.medicalDepartment}
            onChange={onChangeInputs}
            placeholder="진료과를 입력해주세요 (내과 / 외과 / 이비인후과 등)"
          />
          <StyledInput
            REQUIRED
            label="PC사용 대수"
            name="usePCCount"
            type="number"
            value={inputs.usePCCount}
            onChange={onChangeInputs}
            placeholder="병원에서 사용하는 PC 수를 입력하세요"
          />
          <StyledCheckbox
            label="의료영상장비"
            name="medicalImagingEquipment"
            checkBoxList={medicalImagingEquipmentList}
            onChange={handleChecklist}
            values={inputs.medicalImagingEquipment}
          />
          <StyledCheckbox
            label="의료영상장비"
            name="generalInspectionEquipment"
            checkBoxList={generalInspectionEquipmentList}
            values={inputs.generalInspectionEquipment}
            onChange={handleChecklist}
          />
          <StyledCheckbox
            label="의료영상 처리장치"
            name="medicalImageProcessingUnit"
            checkBoxList={medicalImageProcessingUnitList}
            values={inputs.medicalImageProcessingUnit}
            onChange={handleChecklist}
          />
          <StyledInput REQUIRED label="요청자 이름" name="name" value={inputs.name} onChange={onChangeInputs} placeholder="이름을 입력하세요" />
          <StyledInput
            REQUIRED
            label="병/의원명"
            name="hospitalName"
            value={inputs.hospitalName}
            onChange={onChangeInputs}
            placeholder="운영 또는 개원 예정이신 병/의원명을 기입해 주세요"
          />
          <StyledInput REQUIRED label="근무지역" name="region" value={inputs.region} onChange={onChangeInputs} placeholder="근무 지역 또는 개원 예정 지역을 입력해주세요" />
          <StyledInput REQUIRED label="연락처" name="tel" value={inputs.tel} onChange={onChangeInputs} placeholder="전화번호를 입력해주세요" />
          <StyledInput REQUIRED label="E-mail" name="email" value={inputs.email} onChange={onChangeInputs} placeholder="이메일을 입력해주세요" />
          <StyledInput label="기타" name="message" value={inputs.message} onChange={onChangeInputs} placeholder="기타 요청사항 또는 문의사항을 입력해주세요" />
          <AgreeBox label={`개인정보 수집 및\n이용안내`} item="개인정보 수집 및 이용에 대해서 동의합니다.*" values={agree} onChange={handleAgreeCheck} />
          <AgreeBox label="유의사항" item="유의 사항 내용을 확인 하였습니다.*" values={agree} onChange={handleAgreeCheck} />
          <FlexBox justifyContent="center" margin="80px 0">
            <StyledButton title="온라인 견적 문의하기" fontColor={colors.skyblueColor} border={`1px solid ${colors.skyblueColor}`} onClick={handleContact} />
            <StyledButton title="취소" margin="0 0 0 36px" onClick={handleCancel} />
          </FlexBox>
        </InsertBox>
      </Container>
    </>
  );
};

export default EstimatePresenter;
