import styled from "styled-components";
import colors from "../../styles/colors";

const Wrapper = styled.div`
  width: 100%;
  margin: 4px auto 0;
  max-width: 1144px;
`;
const GuideLineBox = styled.div`
  display: flex;
  align-items: center;
`;
const LabelText = styled.span`
  font-size: 12px;
  font-family: 'AppleSdNeoRegular';
  color: ${colors.deepGrayFontColor};
`;

const GuideLine = ({label = []}) => {
    return (
        <Wrapper>
            <GuideLineBox>
                {label.map((text, index) => (
                    <LabelText key={index}>
                        {text}
                        {index !== (label.length - 1) && (
                            <>
                                &nbsp; > &nbsp;
                            </>
                        )}
                    </LabelText>
                ))}
            </GuideLineBox>
        </Wrapper>
    );
};

export default GuideLine;
