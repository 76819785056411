export default {
  // General
  whiteColor: "#FFF",
  blackColor: "#000",

  blueColor: "#4781EF",
  orangeColor: "#F19A38",
  lightGrayColor: "#EBEBEB",
  // Active
  logoBlue: "#3699D7",
  blackFontColor: "#2C2C2C",
  //hover
  logoBlueHover: "#419CD3",
  lightBgColor: "#F4F6F9",

  // Font
  deepOrangeColor: '#CC4D09',
  grayFontColor: "#a2a2a2",
  deepGrayFontColor: "#717171",
  darkGrayColor: "#6a6c6c",
  grayColor: "#E2E2E2",
  purpleColor: "#40195F",
  skyblueColor: "#21419F",
  graybColor: "#BCBCBC",
  cottonColor: "#BBCAF4",

  purpleBorder: '#9187BA',

  // Border
  lightGrayBorder: '#EFEFEF'

};
