import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import colors from "../../../styles/colors";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  margin-bottom: 52px;
  padding-right: 50px;
  position: relative;
  z-index: 1;
  border-radius: 4px;
  cursor: pointer;
`;
const IconBox = styled.div`
  margin-right: 40px;
`;
const Icon = styled.img``;
const Contents = styled.div`
  font-size: 15px;
  word-break: keep-all;
  line-height: 1.2;
`;
const Title = styled.div`
  margin-bottom: 4px;
  font-size: 18px;
`;
const Text = styled.div`
  font-family: "AppleSdNeoRegular";
  line-height: 1.4;
  font-size: 16px;
`;
const HoverBox = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% + 14px);
  top: -4px;
  left: -10px;
  z-index: 0;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.08);
  opacity: 0;
  transition: 0.3s;

  ${Wrapper}:hover & {
    opacity: 1;
  }
`;

const EMRChartList = ({ path = "", src, title = "", contents = "" }) => {
  const navigate = useNavigate();
  return (
    <Wrapper onClick={() => navigate(path)}>
      <HoverBox />
      <IconBox>
        <Icon src={src} />
      </IconBox>
      <Contents>
        <Title>{title}</Title>
        <Text>
          {contents.split("\n").map((text, index) => (
            <span key={`text-${index}`}>
              {text}
              <br />
            </span>
          ))}
        </Text>
      </Contents>
    </Wrapper>
  );
};

export default EMRChartList;
