import styled from "styled-components";

export const Wrapper = styled.div`
  padding-bottom: 80px;
`;
export const Contents = styled.div`
  max-width: 1144px;
  margin: 60px auto 40px;
`;
export const ContentsWrap = styled.div``;
export const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;
export const ImageContents = styled.div`
  padding: 0 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
`;
export const Figure = styled.figure`
  text-align: center;
  max-width: 220px;
  flex: 1;
`;
export const FigCation = styled.figcaption`
  font-size: 15px;
  margin-top: 16px;
`;
export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
