import React from "react";
import styled from "styled-components";
import colors from "../../styles/colors";
import {IoArrowDownCircleOutline} from "react-icons/io5";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: ${({ margin }) => margin};
  cursor: pointer;
  transition: all .3s;

  .arrowDown {
    margin-left: 12px;
    color: ${colors.grayFontColor};
    transition-delay: .32s;
  }

  &:hover .arrowDown {
    color: ${colors.blackColor};
  }
`;
export const DownloadText = styled.div`
  font-size: 15px;
  color: ${colors.grayFontColor};
  position: relative;
  overflow: hidden;

  &:before {
    content: '히포크라테스 브로슈어 다운';
    position: absolute;
    color: ${colors.blackColor};
    top: 0;
    left: 0;
    width: 0;
    overflow: hidden;
    transition: all .3s;
  }

  ${Wrapper}:hover &:before {
    width: 100%;
  }
`;

const BrochureDownload = ({ margin = '60px 0', onClick = () => null }) => {
    return (
        <Wrapper margin={margin} onClick={onClick}>
            <DownloadText>히포크라테스 브로슈어 다운</DownloadText>
            <IoArrowDownCircleOutline className='arrowDown' fontSize={20}/>
        </Wrapper>
    )
}

export default React.memo(BrochureDownload);
