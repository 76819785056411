import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  padding: 0 5%;
`;
const Title = styled.div`
  font-size: 16px;
  margin-bottom: 4px;
`;
const ListBox = styled.ul`
  padding-left: 18px;
  list-style-type: square;
  font-size: 16px;
`;
const List = styled.li`
  font-family: "AppleSdNeoRegular";
  line-height: 1.4;
  list-style: inherit;
  word-break: keep-all;
  margin-bottom: 4px;
`;

const DetailExplanation = ({ contents }) => {
  const { title, serviceDetail } = contents;
  return (
    <Wrapper>
      <Title>{title}</Title>
      <ListBox>
        {serviceDetail.map((item, index) => (
          <List key={`${index}-list`}>{item}</List>
        ))}
      </ListBox>
    </Wrapper>
  );
};

export default React.memo(DetailExplanation);
