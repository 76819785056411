import Footer from "./components/layout/Footer";
import Header from "./components/layout/Header/Header";
import GlobalStyles from "./styles/GlobalStyles";
import Router from "./Router";
import { createContext, useState } from "react";

export const AppContext = createContext(null);

function App() {
  const [serviceActiveTab, setServiceActiveTab] = useState("공지사항");

  return (
    <AppContext.Provider
      value={{
        serviceActiveTab,
        setServiceActiveTab,
      }}
    >
      <GlobalStyles />
      <Header />
      <Router />
      <Footer />
    </AppContext.Provider>
  );
}

export default App;
