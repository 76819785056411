import React, { useState } from 'react';
import { Wrapper, Contents, ContentsWrap, FlexBox, ImageContents, Figure, Image } from '../ServiceDetail.styles';
import DetailExplanation from '../../../components/feature/ServiceDetail/DetailExplanation';
import PageIntro from '../../../components/layout/PageIntro';
import BrochureDownload from '../../../components/share/BrochureDownload';
import NoImage from '../../../assets/images/about_banner.jpeg';
import serviceImg1 from '../../../assets/images/wideType.png';
import serviceImg2 from '../../../assets/images//defaultType.png';
import serviceImg3 from '../../../assets/images/simpleType.png';
import { useQuery } from '@apollo/client';
import { SEE_SERVICE } from '../../../graphql/Service/query';

const ServiceDetailCloud = () => {
  const [serviceType, setServiceType] = useState('EMR');
  const [hsiServiceType, setHsiServiceType] = useState('EMR');
  const [detailTabName, setDetailTabName] = useState('CLOUD');
  const [hsiDetailTabName, setHsiDetailTabName] = useState('CLOUD');

  const { loading, data } = useQuery(SEE_SERVICE, {
    variables: {
      serviceType: serviceType,
      detailTabName: detailTabName,
      hsiServiceType: hsiServiceType,
      hsiDetailTabName: hsiDetailTabName,
    },
  });

  const detailContents = data?.seeServiceContent;
  const serviceImg = data?.seeServiceImg;
  const serviceDetailBanner = data?.seeHPServiceDetail?.hsd_url;

  return (
    <Wrapper>
      <PageIntro
        CENTER
        guideLine={['홈', '서비스', '서비스목록', 'CLOUD']}
        title="사용성을 극대화한 EMR 차트"
        subTitle={`진료관리, 병원경영, 고객관리 등 의료행위에\n집중 할 수 있도록 설계하였습니다.`}
        src={serviceDetailBanner !== '' ? serviceDetailBanner : NoImage}
      />
      {!loading && (
        <Contents>
          {detailContents
            ?.reduce((groups, item, index) => {
              if (index % 2 === 0) {
                groups.push([item]);
              } else {
                groups[groups?.length - 1].push(item);
              }
              return groups;
            }, [])
            .map((group, index) => (
              <ContentsWrap key={index}>
                {index === 0 && <BrochureDownload onClick={() => console.info('Download')} />}
                <FlexBox key={'ServiceBlockGroup' + index}>
                  {group.map((item, innerIndex) => (
                    <DetailExplanation key={'DetailExplanation' + innerIndex} contents={item} />
                  ))}
                </FlexBox>
              </ContentsWrap>
            ))}

          <ImageContents>
            {serviceImg?.length > 0 ? (
              serviceImg?.map((img, index) => (
                <Figure key={index}>
                  <Image src={img.hsi_img} />
                </Figure>
              ))
            ) : (
              <>
                <Figure>
                  <Image src={serviceImg1} />
                </Figure>
                <Figure>
                  <Image src={serviceImg2} />
                </Figure>
                <Figure>
                  <Image src={serviceImg3} />
                </Figure>
              </>
            )}
          </ImageContents>
        </Contents>
      )}
    </Wrapper>
  );
};

export default ServiceDetailCloud;
