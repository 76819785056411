import styled from "styled-components";
import colors from "../../styles/colors";
import support_banner from "../../assets/images/support_banner.jpeg";
import PageIntro from "../../components/layout/PageIntro";
import NoticeTable from "../../components/feature/Support/NoticeTable";
import InquiryForm from "../../components/feature/Support/InquiryForm";
import ServiceCenter from "../../components/feature/Support/ServiceCenter";

const TabMenuBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TabMenu = styled.div`
  font-size: 20px;
  color: ${({ active }) => (active ? colors.blackColor : colors.graybColor)};
  position: relative;
  transition: 0.3s;
  overflow: hidden;
  cursor: pointer;
`;
const Line = styled.div`
  width: 2px;
  height: 16px;
  margin: 0 26px;
  background-color: ${colors.graybColor};
`;
const Container = styled.section`
  max-width: 1144px;
  margin: 80px auto;
`;

const SupportPresenter = ({ activeTab, onClickTabMenu }) => {
  return (
    <>
      <PageIntro
        guideLine={["홈", "고객지원"]}
        title={`고객분들의 목소리에\n늘 세심한 주의를 기울이겠습니다.`}
        subTitle="필요한 메뉴를 선택하시어 연락주시면 최대한 빠르게 답변드리겠습니다."
        src={support_banner}
      />
      <TabMenuBox>
        <TabMenu
          active={activeTab === "공지사항"}
          onClick={() => onClickTabMenu("공지사항")}
        >
          공지사항
        </TabMenu>
        <Line />
        <TabMenu
          active={activeTab === "문의하기"}
          onClick={() => onClickTabMenu("문의하기")}
        >
          문의하기
        </TabMenu>
        <Line />
        <TabMenu
          active={activeTab === "고객센터"}
          onClick={() => onClickTabMenu("고객센터")}
        >
          고객센터
        </TabMenu>
      </TabMenuBox>

      <Container>
        {activeTab === "공지사항" && <NoticeTable />}
        {activeTab === "문의하기" && <InquiryForm />}
        {activeTab === "고객센터" && <ServiceCenter />}
      </Container>
    </>
  );
};

export default SupportPresenter;
