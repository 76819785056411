import styled from "styled-components";

export const BoldText = styled.div`
  font-family: "AppleSdNeoBold";
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: ${({ fontWeight }) => fontWeight};
`;

export const MediumText = styled.div`
  font-family: "AppleSdNeoMedium";
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: ${({ fontWeight }) => fontWeight};
`;

export const RegularText = styled.div`
  font-family: "AppleSdNeoRegular";
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: ${({ fontWeight }) => fontWeight};
`;
