import React from "react";
import styled from "styled-components";
import Checkbox from "./Checkbox";
import colors from "../../styles/colors";

const Wrapper = styled.div`
  padding-top: 30px;
  display: flex;
`;
const Label = styled.div`
  min-width: 156px;
  font-size: 16px;
`;
const CheckList = styled.div`
  padding-bottom: 22px;
  display: grid;
  grid-template-columns: repeat(3, 250px);
  gap: 20px;
  border-bottom: 1px solid ${colors.lightGrayColor};
  font-size: 16px;
`;

const StyledCheckbox = ({
  name,
  label,
  checkBoxList = [],
  onChange,
  values,
}) => {

  return (
    <Wrapper>
      <Label>{label}</Label>
      <CheckList>
        {checkBoxList.map((item, index) => (
          <Checkbox
            key={`${index}-checklist`}
            item={item}
            name={name}
            onChange={onChange}
            values={values}
          />
        ))}
      </CheckList>
    </Wrapper>
  );
};

export default React.memo(StyledCheckbox);
