import React from "react";
import styled from "styled-components";
import colors from "../../../styles/colors";
import { IoChevronForwardOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  flex: 1;
  height: 224px;
  position: relative;
  cursor: pointer;
`;
const Image = styled.img``;
const HoverBox = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.4s;
  background-color: rgba(0, 0, 0, 0.6);

  ${Wrapper}:hover & {
    opacity: 1;
  }
`;
const TextBox = styled.div`
  width: 100%;
  padding: 0 30px;
  color: ${colors.whiteColor};
  position: absolute;
  bottom: 26px;
`;
const Title = styled.div`
  font-size: 18px;
  margin-bottom: 12px;
`;
const SubTitleBox = styled.div`
  display: flex;
  align-items: flex-end;
`;
const SubTitle = styled.div`
  font-size: 14px;
  word-break: keep-all;
  line-height: 1.4;
`;
const ArrowBox = styled.div`
  width: 20px;
  height: 20px;
  padding-left: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-left: 16px;
  background-color: ${colors.cottonColor};
`;

const GirdImage = ({ src, title, subTitle, path }) => {
  const navigate = useNavigate();

  return (
    <Wrapper onClick={() => navigate(path)}>
      <Image src={src} />
      <HoverBox>
        <TextBox>
          <Title>{title}</Title>
          <SubTitleBox>
            <SubTitle>
              {subTitle.split("\n").map((text, index) => (
                <span key={`${index}-text`}>
                  {text}
                  <br />
                </span>
              ))}
            </SubTitle>
            <ArrowBox>
              <IoChevronForwardOutline
                fontSize={15}
                color={colors.skyblueColor}
              />
            </ArrowBox>
          </SubTitleBox>
        </TextBox>
      </HoverBox>
    </Wrapper>
  );
};

export default React.memo(GirdImage);
