import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import colors from "../../../styles/colors";
import PageNation from "../../layout/PageNation";
import { useQuery } from "@apollo/client";
import { SEE_PLATFORM_NOTICE } from "../../../graphql/Support/query";
import { useCookies } from "react-cookie";

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
`;
const Title = styled.div`
  font-size: 18px;
`;
const Section = styled.section`
  width: calc(100% - 250px);
  max-width: 1144px;
  border-top: 2px solid ${colors.blackColor};
`;
const TableHead = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.blackColor};
`;
const TableCell = styled.div`
  font-size: 16px;
  font-family: ${({ fontFamily }) =>
    fontFamily ? fontFamily : "AppleSdNeoRegular"};

  &:first-child {
    width: 88%;
  }

  &:last-child {
    width: 12%;
  }
`;
const TableBody = styled.div`
  height: 420px;
  margin-bottom: 80px;
  overflow-y: scroll;
`;
const TableRow = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.lightGrayColor};
  cursor: pointer;

  &:hover {
    background-color: ${colors.lightBgColor};
  }
`;
const Text = styled.span`
  width: 100%;
  display: inline-block;
  padding-right: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const NoticeTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [take, setTake] = useState(8);
  const [cursor, setCursor] = useState(0);
  const [noticeId, setNoticeId] = useState(0);
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["pnId"]);

  const { loading, error, data } = useQuery(SEE_PLATFORM_NOTICE, {
    variables: {
      orderBy: "desc",
      year: 0,
      take: take,
      cursor: cursor,
    },
  });

  if (loading) return null;
  if (error) return console.log(error.message);
  // if (!data) return null;


  const noticeList = data.seePlatformNotice.platformNotice;
  const noticeListLength = data.seePlatformNotice.totalLength;

  const handleClickPage = (page) => {
    setCurrentPage(page);
    if (page !== 1) {
      setCursor((page - 1) * take);
    } else {
      setCursor(0);
    }
  };

  const dateFormat = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  return (
    <Wrapper>
      <Title>공지사항</Title>
      <Section>
        <TableHead>
          <TableCell fontFamily="AppleSdNeoBold">제목</TableCell>
          <TableCell fontFamily="AppleSdNeoBold">작성일</TableCell>
        </TableHead>
        <TableBody>
          {noticeList.map((notice) => (
            <TableRow
              key={notice.pn_id}
              onClick={() => {
                setNoticeId(notice.pn_id);
                navigate(`/support/notice`);
                setCookie("pnId", notice.pn_id, { path: "/" });
              }}
            >
              <TableCell>
                <Text>{notice.pn_title}</Text>
              </TableCell>
              <TableCell>{dateFormat(notice.pn_createdAt)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <PageNation
          TAKE_LIST
          takeOption={[8, 16, 24]}
          currentPage={currentPage}
          take={take}
          setTake={setTake}
          // totalLength={noticeList.length}
          totalLength={noticeListLength}
          handleClickPage={handleClickPage}
        />
      </Section>
    </Wrapper>
  );
};

export default React.memo(NoticeTable);
