import React, { useContext } from "react";
import styled, { css } from "styled-components";
import colors from "../../../styles/colors";
import ms_logo from "../../../assets/icons/medical_logo_white.svg";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../App";

const Wrapper = styled.div`
  width: 100vw;
  height: 680px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${colors.purpleColor};
  clip-path: circle(0 at calc(100vw - ((100vw - 1144px) / 2) + 8px) 6%);
  transition: 0.45s ease-in-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      clip-path: circle(100% at top);
    `}
`;
const Header = styled.div`
  width: 100%;
  height: 80px;
  position: relative;
  max-width: 1144px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 60px;
`;
const Image = styled.img`
  width: 140px;
`;
const Section = styled.section`
  color: ${colors.whiteColor};
  display: flex;
  max-width: 1144px;
  margin: 0 auto;
`;
const MenuList = styled.ul`
  margin-right: 170px;

  &:nth-child(2) {
    margin-right: 80px;
  }
`;
const MenuTitle = styled.li`
  font-size: 20px;
  margin-bottom: 28px;
`;
const ItemList = styled.li`
  font-size: 14px;
  margin-bottom: 14px;
  position: relative;
  cursor: pointer;
`;
const Text = styled.span`
  position: relative;
  color: ${colors.whiteColor};

  &::after {
    content: "";
    width: 0;
    height: 1px;
    left: 0;
    bottom: -3px;
    position: absolute;
    background-color: ${colors.whiteColor};
    transition: 0.3s;
  }

  ${ItemList}:hover &::after {
    width: 100%;
  }
`;

const HamburgerMenuBox = ({ isOpen }) => {
  const navigate = useNavigate();
  const { setServiceActiveTab } = useContext(AppContext);

  return (
    <Wrapper isOpen={isOpen}>
      <Header>
        <Image src={ms_logo} />
      </Header>
      <Section>
        <MenuList>
          <MenuTitle>회사소개</MenuTitle>
          <ItemList>
            {/* <Link to="/service/detail/emr"> */}
            <Text onClick={() => navigate("/about")}>회사소개</Text>
            {/* </Link> */}
          </ItemList>
        </MenuList>
        <MenuList>
          <MenuTitle>서비스</MenuTitle>
          <ItemList>
            <Text onClick={() => navigate("/service/detail/emr")}>
              Hippocrates
            </Text>
          </ItemList>
          <ItemList>
            <Text onClick={() => navigate("/service/detail/check")}>
              Hippocrates Check
            </Text>
          </ItemList>
          <ItemList>
            <Text onClick={() => navigate("/service/detail/image")}>
              Hippocrates Image
            </Text>
          </ItemList>
          <ItemList>
            <Text onClick={() => navigate("/service/detail/lab")}>
              Hippocrates LAB
            </Text>
          </ItemList>
          <ItemList>
            <Text onClick={() => navigate("/service/detail/eSign")}>
              Hippocrates e-Sign
            </Text>
          </ItemList>
          <ItemList>
            <Text onClick={() => navigate("/service/detail/desk")}>
              Hippocrates Desk
            </Text>
          </ItemList>
          <ItemList>
            <Text onClick={() => navigate("/service/detail/cloud")}>
              Hippocrates Cloud Service
            </Text>
          </ItemList>
          <ItemList>
            <Text onClick={() => navigate("/service/detail/did")}>
              Hippocrates Web DID
            </Text>
          </ItemList>
          <ItemList>
            <Text onClick={() => navigate("/service/detail/checkUp")}>
              Hippocrates Medical Checkup
            </Text>
          </ItemList>
          <ItemList>
            <Text onClick={() => navigate("/service/detail/chart")}>
              Hippocrates Pen Chart
            </Text>
          </ItemList>
          <ItemList>
            <Text onClick={() => navigate("/service/detail/crm")}>
              라온제나 CRM
            </Text>
          </ItemList>
          <ItemList>
            <Text onClick={() => navigate("/service/detail/emr")}>
              기타서비스
            </Text>
          </ItemList>
        </MenuList>
        <MenuList>
          <MenuTitle>온라인견적</MenuTitle>
          <ItemList>
            <Text onClick={() => navigate("/estimate")}>온라인 견적하기</Text>
          </ItemList>
        </MenuList>
        <MenuList>
          <MenuTitle>고객지원</MenuTitle>
          <ItemList>
            <Text
              onClick={() => {
                navigate("/support");
                setServiceActiveTab("공지사항");
              }}
            >
              공지사항
            </Text>
          </ItemList>
          <ItemList>
            <Text
              onClick={() => {
                navigate("/support");
                setServiceActiveTab("문의하기");
              }}
            >
              문의하기
            </Text>
          </ItemList>
          <ItemList>
            <Text
              onClick={() => {
                navigate("/support");
                setServiceActiveTab("고객센터");
              }}
            >
              고객센터
            </Text>
          </ItemList>
        </MenuList>
      </Section>
    </Wrapper>
  );
};

export default React.memo(HamburgerMenuBox);
