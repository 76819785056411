import React from "react";
import styled, {css} from "styled-components";
import colors from "../../../styles/colors";

const Wrapper = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: 14px;
  cursor: pointer;
  z-index: 100;
`;
const Button = styled.div`
  width: 16px;
  height: 12px;
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &:hover {
    &:after {
      content: '';
      width: 16px;
      height: 16px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;
const Line = styled.div`
  height: calc(12px / 6);
  border-radius: 4px;
  transition: .3s;
  background-color: ${colors.blackColor};

  ${({isOpen}) => isOpen && css`
    background-color: ${colors.whiteColor};

    &:nth-child(1) {
      width: 22px;
      transform-origin: left;
      transform: rotate(45deg);
    }

    &:nth-child(2) {
      opacity: 0;
    }

    &:nth-child(3) {
      width: 22px;
      transform-origin: right;
      transform: translate(-28%, -490%) rotate(-45deg);
    }
  `};
`;

const AnimatedHamburgerButton = ({isOpen, onClick}) => {
    return (
        <Wrapper onClick={onClick}>
            <Button>
                <Line isOpen={isOpen}/>
                <Line isOpen={isOpen}/>
                <Line isOpen={isOpen}/>
            </Button>
        </Wrapper>
    )
}

export default React.memo(AnimatedHamburgerButton);
