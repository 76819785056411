import HomePresenter from "./HomePresenter";
import grid_img1 from "../../assets/Home/grid_img1.png";
import grid_img2 from "../../assets/Home/grid_img2.png";
import grid_img3 from "../../assets/Home/grid_img3.png";
import grid_img4 from "../../assets/Home/grid_img4.png";
import grid_img5 from "../../assets/Home/grid_img5.png";
import grid_img6 from "../../assets/Home/grid_img6.png";
import grid_img7 from "../../assets/Home/grid_img7.png";
import grid_img8 from "../../assets/Home/grid_img8.png";
import grid_img9 from "../../assets/Home/grid_img9.png";
import grid_img10 from "../../assets/Home/grid_img10.png";
import grid_img11 from "../../assets/Home/grid_img11.png";
import grid_img12 from "../../assets/Home/grid_img12.png";

const GRID_IMAGES = [
  {
    src: grid_img1,
    title: "Hippocrates",
    subTitle: "스마트한 EMR 차트",
    path: "/service/detail/emr",
  },
  {
    src: grid_img2,
    title: "Hippocrates Check",
    subTitle: "실시간 사전 점검 솔루션",
    path: "/service/detail/check",
  },
  {
    src: grid_img3,
    title: "Hippocrates Image",
    subTitle: "다양한 의료 영상장비와 연결",
    path: "/service/detail/image",
  },
  {
    src: grid_img4,
    title: "Hippocrates LAB",
    subTitle:
      "수탁 검사기관 들과 실시간 연계하여\n신속하고 정확하게 검사결과를 관리하는 솔루션",
    path: "/service/detail/lab",
  },
  {
    src: grid_img5,
    title: "Hippocrates e-Sign",
    subTitle:
      "진료기록에 공동인증서명을 추가하여\n전자의무기록문서로 변환시키는 솔루션",
    path: "/service/detail/eSign",
  },
  {
    src: grid_img6,
    title: "Hippocrates Desk",
    subTitle: "비-대면 무인, 자동 접수 솔루션",
    path: "/service/detail/desk",
  },
  {
    src: grid_img7,
    title: "Hippocrates Cloud Service",
    subTitle:
      "병원 데이터를 아마존 웹서버(AWS)에\n안전하게 보관 및 관리하는 서비스",
    path: "/service/detail/cloud",
  },
  {
    src: grid_img8,
    title: "Hippocrates Web DID",
    subTitle:
      "대기환자리스트 현황, 음성호출, 병원홍보 등\n다양한 미디어 컨텐츠 제공",
    path: "/service/detail/did",
  },
  {
    src: grid_img9,
    title: "Hippocrates Medical Checkup(건강검진)",
    subTitle:
      "건강검진 접수에서 검사결과 연동 및\n공단 청구까지 실시간 으로 관리하는 솔루션",
    path: "/service/detail/checkUp",
  },
  {
    src: grid_img10,
    title: "Hippocrates Pen Chart",
    subTitle:
      "차트 연동 태블릿 앱 서비스로 각종 동의서 및\n문진표 작성 등 다양한 기능 제공 솔루션",
    path: "/service/detail/chart",
  },
  {
    src: grid_img11,
    title: "라온제나 CRM",
    subTitle:
      "진료예약, 검사예약, 예방접종 등\n환자에게 맞춤 메시지 발송 및 관리 솔루션",
    path: "/service/detail/crm",
  },
  {
    src: grid_img12,
    title: "기타서비스",
    subTitle:
      "실손 보험 간소화, 진료정보교류, \n마약류통합관리 등 다양한 부가서비스 제공",
    path: "/service/detail/etc",
  },
];

const HomeContainer = () => {
  return <HomePresenter GRID_IMAGES={GRID_IMAGES} />;
};

export default HomeContainer;
