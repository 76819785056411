import React, { useContext } from "react";
import styled from "styled-components";
import { IoChevronForwardOutline } from "react-icons/io5";
import colors from "../../../styles/colors";
import serviceIntroIcon from "../../../assets/icons/serviceIntroIcon.svg";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../App";

const Wrapper = styled.div`
  max-width: calc(1144px / 4);
  height: 100%;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  border-right: 1px solid ${colors.lightGrayBorder};
  margin-right: 20px;

  &:last-child {
    border-right: none;
  }
`;
const Title = styled.div`
  font-size: 18px;
  flex: 1;
`;
const Contents = styled.div`
  flex: 2;
`;
const EllipsisText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const BoldText = styled.div`
  font-size: 16px;
  line-height: 1.2;
  word-break: keep-all;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const Link = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  .arrow {
    margin-left: 16px;
  }
`;
const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 14px;
  margin-bottom: 12px;
  border-bottom: 1px solid ${colors.deepGrayFontColor};
`;
const SubText = styled.div`
  color: ${colors.deepGrayFontColor};
  font-family: "AppleSdNeoRegular";
  font-size: 16px;
  line-height: 1.4;
  margin-top: 12px;
`;
const Icon = styled.img``;
const DateText = styled.div`
  font-family: "AppleSdNeoRegular";
  text-align: right;
`;

const ShortcutBox = ({ title = "", linkText = "", data, path }) => {
  const navigate = useNavigate();
  const { setServiceActiveTab } = useContext(AppContext);
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Contents>
        {title === "공지" && (
          <>
            <EllipsisText>{data?.subTitle}</EllipsisText>
            <SubText>{data?.subTitle}</SubText>
          </>
        )}

        {title === "서비스 소개" && (
          <>
            <FlexBox>
              <BoldText>{data?.subTitle}</BoldText>
              <Icon src={serviceIntroIcon} />
            </FlexBox>
            <DateText>{data?.date} 기준</DateText>
          </>
        )}

        {(title === "인사말" || title === "견적문의") && (
          <BoldText>{data?.subTitle}</BoldText>
        )}
      </Contents>
      <Link
        onClick={() => {
          navigate(path);
          if (title === "공지") {
            setServiceActiveTab("공지사항");
          }
        }}
      >
        {linkText}
        <IoChevronForwardOutline className="arrow" fontSize={16} />
      </Link>
    </Wrapper>
  );
};

export default React.memo(ShortcutBox);
