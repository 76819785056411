import React from "react";
import styled from "styled-components";
import colors from "../../styles/colors";

const Button = styled.button`
  width: 200px;
  height: 60px;
  font-size: 18px;
  color: ${({ fontColor }) => fontColor};
  margin: ${({ margin }) => margin};
  border: ${({ border }) => border};
  background-color: ${({ bgColor }) => bgColor};
  transition: .3s;
  
  &:hover {
    color: ${({ bgColor }) => bgColor};
    background-color: ${({ fontColor }) => fontColor};
  }
  
  &:active {
    opacity: 0.7;
  }
`;

const StyledButton = ({
                          title,
                          fontColor = colors.graybColor,
                          margin = 0,
                          border = `1px solid ${colors.graybColor}`,
                          bgColor = colors.whiteColor,
                          onClick = () => null,
                      }) => {
    return (
        <Button
            fontColor={fontColor}
            margin={margin}
            border={border}
            bgColor={bgColor}
            onClick={onClick}
        >
            {title}
        </Button>
    )
}

export default React.memo(StyledButton);
