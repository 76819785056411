import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import StyledInput from '../../share/StyledInput';
import StyledTextarea from '../../share/StyledTextarea';
import AgreeBox from '../../share/AgreeBox';
import StyledButton from '../../share/StyledButton';
import colors from '../../../styles/colors';
import { useMutation } from '@apollo/client';
import { CREATE_GENERAL_INQUIRY } from '../../../graphql/Support/mutation';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const Title = styled.div`
  font-size: 18px;
`;
const Section = styled.section`
  width: calc(100% - 250px);
  max-width: 1144px;
  border-top: 2px solid ${colors.blackColor};
`;
const FlexBox = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => (flexDirection ? flexDirection : 'column')};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'center')};
  margin: ${({ margin }) => (margin ? margin : 0)};
`;

const InquiryForm = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    title: '',
    name: '',
    hospitalName: '',
    region: '',
    tel: '',
    email: '',
    message: '',
  });
  const [agree, setAgree] = useState([]);

  const onChangeInputs = useCallback(
    (e) => {
      const { name, value } = e.target;
      setInputs({
        ...inputs,
        [name]: value,
      });
    },
    [inputs]
  );

  // 동의하기
  const handleAgreeCheck = useCallback(
    (_, value, isChecked) => {
      if (isChecked) {
        setAgree((prev) => [...prev, value]);
        return;
      }

      if (!isChecked && agree.includes(value)) {
        setAgree((prev) => prev.filter((item) => item !== value));
      }
    },
    [agree]
  );

  const [createGeneralInquiry] = useMutation(CREATE_GENERAL_INQUIRY);

  const handleContact = useCallback(async () => {
    const { title, name, hospitalName, region, tel, email, message } = inputs;

    if (title === '' || name === '' || hospitalName === '' || region === '' || tel === '' || email === '') {
      alert('필수 항목을 모두 입력해주세요.');
      return;
    }
    
    if(agree.length !== 2){
      alert("개인정보 수집 및 유의사항에 동의해주세요.");
      return;
    }

    try {
      const { data } = await createGeneralInquiry({
        variables: {
          title: title,
          name: name,
          hospitalName: hospitalName,
          workArea: region,
          cellphone: tel,
          email: email,
          text: message,
        },
      });

      if (data.createGeneralInquiry) {
        alert("문의 등록되었습니다.");
        setInputs({
          title: '',
          name: '',
          hospitalName: '',
          region: '',
          tel: '',
          email: '',
          message: '',
        });
        setAgree([]); 
      }
    } catch (e) {}
  }, [inputs, agree]);

  const handleCancel = useCallback(
    (e) => {
      if (window.confirm("정말로 취소하시겠습니까?")) {
        const { name } = e.target;
        const value = '';
        setInputs({
          ...inputs,
          [name]: value,
        });
        setAgree([]); 
      } else {
        return;
      }
    },
    []
  );


  return (
    <Wrapper>
      <Title>문의하기</Title>
      <Section>
        <StyledInput REQUIRED label="제목" name="title" value={inputs.title} onChange={onChangeInputs} placeholder="제목을 입력해주세요" />
        <StyledInput REQUIRED label="문의자 이름" name="name" value={inputs.name} onChange={onChangeInputs} placeholder="문의자 이름을 입력해주세요" />
        <StyledInput
          REQUIRED
          label="병/의원명"
          name="hospitalName"
          value={inputs.hospitalName}
          onChange={onChangeInputs}
          placeholder="영 또는 개원 예정이신 병/의원명을 기입해 주세요"
        />
        <StyledInput REQUIRED label="근무지역" name="region" value={inputs.region} onChange={onChangeInputs} placeholder="근무 지역 또는 개원 예정 지역을 입력해주세요" />
        <StyledInput REQUIRED label="연락처" name="tel" value={inputs.tel} onChange={onChangeInputs} placeholder="전화번호를 입력해주세요" />
        <StyledInput REQUIRED label="E-mail" name="email" value={inputs.email} onChange={onChangeInputs} placeholder="이메일을 입력해주세요" />
        <StyledTextarea label="문의사항" name="message" value={inputs.message} onChange={onChangeInputs} placeholder="기타 요청사항 또는 문의사항을 입력해주세요" />
        <FlexBox margin="80px 0">
          <AgreeBox label={`개인정보 수집 및\n이용안내`} item="개인정보 수집 및 이용에 대해서 동의합니다.*" values={agree} onChange={handleAgreeCheck} />
        </FlexBox>
        <FlexBox margin="80px 0">
          <AgreeBox label="유의사항" item="유의 사항 내용을 확인 하였습니다.*" values={agree} onChange={handleAgreeCheck} />
        </FlexBox>
        <FlexBox flexDirection="row">
          <StyledButton title="문의하기" fontColor={colors.skyblueColor} border={`1px solid ${colors.skyblueColor}`} onClick={handleContact} />
          <StyledButton title="취소" margin="0 0 0 36px" onClick={handleCancel} />
        </FlexBox>
      </Section>
    </Wrapper>
  );
};

export default React.memo(InquiryForm);
