import {useCallback, useContext, useState} from "react";
import SupportPresenter from "./SupportPresenter";
import {AppContext} from "../../App";

const SupportContainer = () => {
    const { serviceActiveTab, setServiceActiveTab } = useContext(AppContext);

    const onClickTabMenu = useCallback(menu => setServiceActiveTab(menu), []);

    return (
        <SupportPresenter
            activeTab={serviceActiveTab}
            onClickTabMenu={onClickTabMenu}
        />
    );
};

export default SupportContainer;
