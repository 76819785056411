import React, { memo } from "react";
import colors from "../../styles/colors";
import styled from "styled-components";

const Wrapper = styled.div`
  padding-top: 30px;
  display: flex;
`;
const Label = styled.div`
  min-width: 156px;
  font-size: 16px;
`;
const Star = styled.span`
  color: ${colors.deepOrangeColor};
`;
const Input = styled.input`
  width: 100%;
  font-size: 16px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colors.lightGrayColor};

  &::placeholder {
    font-family: "AppleSdNeoRegular";
    color: ${colors.graybColor};
  }
`;

const StyledInput = ({
  REQUIRED,
  label,
  type = "text",
  name,
  placeholder,
  value,
  onChange,
  maxLength,
}) => {
  return (
    <Wrapper>
      <Label>
        {label}&nbsp;
        {REQUIRED && <Star>*</Star>}
      </Label>
      <Input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        placeholder={placeholder}
      />
    </Wrapper>
  );
};

export default memo(StyledInput);
