import React, { useCallback, useState } from "react";
import styled, { css } from "styled-components";
import colors from "../../styles/colors";

const Box = styled.div`
  position: relative;
  margin-left: 36px;
`;
const Label = styled.label`
  font-size: 15px;
  font-weight: 500;
  color: ${({ fontColor }) => (fontColor ? fontColor : colors.graybColor)};
  font-family: ${({ fontFamily }) => fontFamily};
  margin: ${({ margin }) => (margin ? margin : 0)};
  display: inline-block;
  cursor: pointer;

  &:before {
    content: "";
    display: inline-block;
    top: 2px;
    left: -30px;
    vertical-align: top;
    cursor: pointer;
    -webkit-transition: -webkit-transform 0.4s
      cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -moz-transition: -moz-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    z-index: 1;
    width: 12px;
    height: 7px;
    border: 2px solid ${colors.graybColor};
    border-top-style: none;
    border-right-style: none;
    position: absolute;
  }

  &:after {
    content: "";
    border: 2px solid ${colors.graybColor};
    display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    margin-left: -25px;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
    left: -10px;
    top: -3px;
    background-color: ${colors.whiteColor};
  }

  ${({ checked }) =>
    checked &&
    css`
      &:before {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        border-color: ${colors.whiteColor};
      }

      &:after {
        border-color: ${colors.skyblueColor};
        background-color: ${colors.skyblueColor};
      }
    `}
`;
const Input = styled.input`
  position: absolute;
  opacity: 0;
  height: auto;
  z-index: -1;
`;

const Checkbox = ({
  fontColor,
  fontFamily = "AppleSdNeoRegular",
  item,
  margin,
  name,
  values = [],
  onChange = () => null,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const checkHandler = useCallback(
    (e, value) => {
      setIsChecked(!isChecked);
      onChange(name, value, e.target.checked);
    },
    [isChecked]
  );

  return (
    <Box>
      <Input
        id={item}
        type="checkbox"
        onChange={(e) => checkHandler(e, item)}
      />
      <Label
        htmlFor={item}
        fontColor={fontColor}
        fontFamily={fontFamily}
        margin={margin}
        checked={values.includes(item)}
      >
        {item}
      </Label>
    </Box>
  );
};

export default React.memo(Checkbox);
