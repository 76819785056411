import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Service from './pages/Service/ServiceList';
import Support from './pages/Support';
import Estimate from './pages/Estimate';
import ServiceDetailEmr from './pages/ServiceDetail/Emr';
import ServiceDetailCheck from './pages/ServiceDetail/Check';
import ServiceDetailImage from './pages/ServiceDetail/Image';
import ServiceDetailLab from './pages/ServiceDetail/Lab';
import ServiceDetailESign from './pages/ServiceDetail/ESign';
import ServiceDetailDesk from './pages/ServiceDetail/Desk';
import ServiceDetailCloud from './pages/ServiceDetail/Cloud';
import ServiceDetailDid from './pages/ServiceDetail/Did';
import ServiceDetailCheckUp from './pages/ServiceDetail/CheckUp';
import ServiceDetailChart from './pages/ServiceDetail/Chart';
import ServiceDetailCrm from './pages/ServiceDetail/Crm';
import ServiceDetailEtc from './pages/ServiceDetail/Etc';
import ServiceDetailAppointment from './pages/ServiceDetail/Appointment';
import ServiceDetailDidMonitor from './pages/ServiceDetail/DidMonitor';
import ServiceDetailInsurance from './pages/ServiceDetail/Insurance';
import ServiceDetailSchedule from './pages/ServiceDetail/Schedule';
import NoticeDetail from './pages/Notice';

const Router = () => {
  //존재하지 않는 경로로 이동 시 홈으로 리다이렉트
  const NotFound = () => <Navigate to="/" />;

  return (
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/about" exact element={<About />} />
      <Route path="/estimate" exact element={<Estimate />} />
      <Route path="/service" exact element={<Service />} />
      <Route path="/service/detail/emr" exact element={<ServiceDetailEmr />} />
      <Route path="/service/detail/check" exact element={<ServiceDetailCheck />} />
      <Route path="/service/detail/image" exact element={<ServiceDetailImage />} />
      <Route path="/service/detail/lab" exact element={<ServiceDetailLab />} />
      <Route path="/service/detail/eSign" exact element={<ServiceDetailESign />} />
      <Route path="/service/detail/desk" exact element={<ServiceDetailDesk />} />
      <Route path="/service/detail/cloud" exact element={<ServiceDetailCloud />} />
      <Route path="/service/detail/did" exact element={<ServiceDetailDid />} />
      <Route path="/service/detail/checkUp" exact element={<ServiceDetailCheckUp />} />
      <Route path="/service/detail/chart" exact element={<ServiceDetailChart />} />
      <Route path="/service/detail/crm" exact element={<ServiceDetailCrm />} />
      <Route path="/service/detail/etc" exact element={<ServiceDetailEtc />} />
      <Route path="/service/detail/appointmemt" exact element={<ServiceDetailAppointment />} />
      <Route path="/service/detail/didMonitor" exact element={<ServiceDetailDidMonitor />} />
      <Route path="/service/detail/insurance" exact element={<ServiceDetailInsurance />} />
      <Route path="/service/detail/schedule" exact element={<ServiceDetailSchedule />} />
      <Route path="/support" exact element={<Support />} />
      <Route path="/support/notice" exact element={<NoticeDetail />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
