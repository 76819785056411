import React from "react";
import styled from "styled-components";
import map from "../../../assets/Home/map.png";

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 120px;
`;
const Title = styled.div`
  width: 220px;
  font-size: 18px;
`;
const Contents = styled.div``;
const MapBox = styled.div`
  width: 100%;
  //height: 332px;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const AddressText = styled.div`
  font-family: "AppleSdNeoRegular";
  margin-top: 24px;
  font-size: 18px;
`;

const Address = () => {
  return (
    <Wrapper>
      <Title>위치</Title>
      <Contents>
        <MapBox>
          <Image src={map} />
        </MapBox>

        <AddressText>
          서울시 금천구 가산디지털2로 123 월드메르디앙2차 707호
        </AddressText>
      </Contents>
    </Wrapper>
  );
};

export default React.memo(Address);
