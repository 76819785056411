import styled from "styled-components";
import colors from "../../styles/colors";
import { RegularText } from "../../components/share/StyledText";

export const Wrapper = styled.div`
  padding-bottom: 80px;
`;
export const Contents = styled.div`
  max-width: 1144px;
  margin: 0 auto 0;
  min-height: calc(100vh - 580px);
`;
export const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;
export const NoticeWrap = styled.div`
  border-top: 1px solid ${colors.deepGrayFontColor};
  border-bottom: 1px solid ${colors.lightGrayBorder};
  padding: 40px 0;
  max-height: calc(100vh - 580px);
`;
export const Text = styled(RegularText)`
  line-height: 26px;
  min-height: 470px;
`;
export const Attachment = styled.div`
  padding: 40px 0 0;
`;
export const GrayText = styled(RegularText)`
  color: #b1b1b1;
  font-size: 14px;
  margin-bottom: 20px;
`;
export const FileWrap = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;
export const File = styled(RegularText)`
  min-width: 184px;
  max-width: 224px;
  height: 52px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  border: 1px solid ${colors.lightGrayBorder};
  border-radius: 4px;
  overflow: hidden;
`;
export const Thumbnail = styled.img`
  width: 52px;
  height: 52px;
  border-radius: 4px 0px 0px 4px;
  object-fit: cover;
`;
export const FileInfo = styled.div`
  flex: 1;
  padding: 0 12px;
`;
export const FileName = styled(RegularText)``;
export const FileSize = styled(RegularText)`
  color: #b1b1b1;
`;
