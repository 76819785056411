import React from "react";
import styled from "styled-components";
import colors from "../../../styles/colors";
import map from "../../../assets/Home/map.png";

const Contents = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const Title = styled.div`
  font-size: 18px;
`;
const Section = styled.section`
  width: calc(100% - 250px);
  max-width: 1144px;
  padding: 8px 0 80px;
  border-top: 2px solid ${colors.blackColor};
`;
const FlexRow = styled.div`
  padding: 12px 0;
  display: flex;
  align-items: flex-start;
  font-size: 16px;
`;
const BoldText = styled.div`
  min-width: 156px;
`;
const InfoText = styled.div`
  font-family: "AppleSdNeoRegular";
`;
const MapBox = styled.div`
  width: 100%;
  height: 332px;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const ServiceCenter = () => {
  return (
    <>
      <Contents>
        <Title>서울 (본사)</Title>
        <Section>
          <FlexRow>
            <BoldText>주소</BoldText>
            <InfoText>
              서울시 금천구 가산디지털2로 123 월드메르디앙2차 707호
            </InfoText>
          </FlexRow>
          <FlexRow>
            <BoldText>전화</BoldText>
            <InfoText>02-2025-4333</InfoText>
          </FlexRow>
          <FlexRow>
            <BoldText>팩스</BoldText>
            <InfoText>02-2025-4337</InfoText>
          </FlexRow>
          <FlexRow>
            <BoldText>위치</BoldText>
            <MapBox>
              <Image src={map} />
            </MapBox>
          </FlexRow>
        </Section>
      </Contents>
      <Contents>
        <Title>대구 대리점</Title>
        <Section>
          <FlexRow>
            <BoldText>주소</BoldText>
            <InfoText>대구광역시 동구 효목동 46-11번지 2층</InfoText>
          </FlexRow>
          <FlexRow>
            <BoldText>전화</BoldText>
            <InfoText>053-243-2653</InfoText>
          </FlexRow>
          <FlexRow>
            <BoldText>팩스</BoldText>
            <InfoText>030-3443-7525</InfoText>
          </FlexRow>
          <FlexRow>
            <BoldText>위치</BoldText>
            <MapBox>
              <Image src={map} />
            </MapBox>
          </FlexRow>
        </Section>
      </Contents>
      <Contents>
        <Title>부산 지사</Title>
        <Section>
          <FlexRow>
            <BoldText>주소</BoldText>
            <InfoText>부산 연제구 연산동 652-3</InfoText>
          </FlexRow>
          <FlexRow>
            <BoldText>전화</BoldText>
            <InfoText>051-900-2811</InfoText>
          </FlexRow>
          <FlexRow>
            <BoldText>팩스</BoldText>
            <InfoText>051-900-2812</InfoText>
          </FlexRow>
          <FlexRow>
            <BoldText>위치</BoldText>
            <MapBox>
              <Image src={map} />
            </MapBox>
          </FlexRow>
        </Section>
      </Contents>
    </>
  );
};

export default React.memo(ServiceCenter);
