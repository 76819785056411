import React, { useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/navigation";
// import logo1 from "../../../assets/Home/banner1.png";
// import logo2 from "../../../assets/Home/banner2.png";
// import banner3 from "../../../assets/Home/banner3.png";
import colors from "../../../styles/colors";
import { IoChevronForwardOutline, IoChevronBackOutline } from "react-icons/io5";
import { SEE_HPMAIN_IMG } from "../../../graphql/Home/query";

const Wrapper = styled.div`
  height: 436px;
  position: relative;

  .swiper-pagination-horizontal {
    font-size: 12px;
    color: ${colors.whiteColor};
    position: absolute;
    bottom: 46px;
    right: calc((100vw - 986px) / 2);
    z-index: 1;
  }
`;
const BannerImage = styled.div`
  width: 100%;
  height: 436px;
  background-color: rgba(0, 0, 0, 0.4);
`;
const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;
const Contents = styled.div`
  max-width: 1144px;
  line-height: 1.4;
  color: ${colors.whiteColor};
  position: absolute;
  left: calc((100vw - 1144px) / 2);
  bottom: 30px;
  margin: 0 auto;
  z-index: 2;
`;
const Title = styled.span`
  font-size: 30px;
`;
const Line = styled.div`
  width: 572px;
  height: 2px;
  margin: 20px 0;
  background-color: ${colors.whiteColor};
`;
const Subtitle = styled.span`
  font-size: 16px;
`;
const ArrowBox = styled.div`
  display: flex;
  position: absolute;
  z-index: 1;
  bottom: 40px;
  right: calc((100vw - 1144px) / 2);
`;
const SlideArrow = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${colors.whiteColor};
  cursor: pointer;

  & + & {
    margin-left: 8px;
  }
`;

const SlideBanner = () => {
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const { loading, error, data } = useQuery(SEE_HPMAIN_IMG);

  if (loading) return null;
  if (error) return console.log(error.message);
  if (!data) return null;

  let newHPMain = [];
  data.seeHPMain.map((item) => {
    if (item.hm_url !== "") {
      newHPMain.push(item.hm_url);
    }
  });

  const banners = [
    {
      id: 1,
      src: newHPMain[0],
      title: "독보적인 경험을 바탕으로 한\n효율적이고 스마트한 EMR 차트",
      subtitle:
        "최신 IT기술을 접목하여 사용자들의 편의성과 효율을 극대화한 솔루션으로 진료관리, 병원경영,\n고객관리 등 의료인이 의료행위에 집중할 수 있도록 설계된 EMR 차트입니다.",
    },
    {
      id: 2,
      src: newHPMain[1],
      title: "독보적인 경험을 바탕으로 한\n효율적이고 스마트한 EMR 차트",
      subtitle:
        "최신 It기술을 접목하여 사용자들의 편의성과 효율을 극대화한 솔루션으로 진료관리, 병원경영,\n고객관리 등 의료인이 의료행위에 집중할 수 있도록 설계된 EMR 차트입니다.",
    },
    {
      id: 3,
      src: newHPMain[2],
      title: "독보적인 경험을 바탕으로 한\n효율적이고 스마트한 EMR 차트",
      subtitle:
        "최신 It기술을 접목하여 사용자들의 편의성과 효율을 극대화한 솔루션으로 진료관리, 병원경영,\n고객관리 등 의료인이 의료행위에 집중할 수 있도록 설계된 EMR 차트입니다.",
    },
  ];

  return (
    <Wrapper>
      <Swiper
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        pagination={{ type: "fraction" }}
        modules={[Autoplay, Navigation, Pagination]}
        onSlideChange={(e) => setMainImageIndex(e.activeIndex)}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
          swiper.activeIndex = mainImageIndex;
          swiper.navigation.update();
        }}
      >
        {banners.map((banner, index) => (
          <SwiperSlide key={`img-${index}`}>
            <BannerImage>
              <Image src={banner.src} />
              <Contents>
                <Title>
                  {banner.title.split("\n").map((text, index) => (
                    <span key={`text-${index}`}>
                      {text}
                      <br />
                    </span>
                  ))}
                </Title>
                <Line />
                <Subtitle>
                  {banner.subtitle.split("\n").map((text, index) => (
                    <span key={`text-${index}`}>
                      {text}
                      <br />
                    </span>
                  ))}
                </Subtitle>
              </Contents>
            </BannerImage>
          </SwiperSlide>
        ))}

        <ArrowBox>
          <SlideArrow ref={navigationPrevRef}>
            <IoChevronBackOutline fontSize={20} color={colors.whiteColor} />
          </SlideArrow>
          <SlideArrow ref={navigationNextRef}>
            <IoChevronForwardOutline fontSize={20} color={colors.whiteColor} />
          </SlideArrow>
        </ArrowBox>
      </Swiper>
    </Wrapper>
  );
};

export default React.memo(SlideBanner);
