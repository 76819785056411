import React, {memo} from "react";
import colors from "../../styles/colors";
import styled from "styled-components";

const Wrapper = styled.div`
  padding-top: 30px;
  display: flex;
`;
const Label = styled.div`
  min-width: 156px;
`;
const Star = styled.span`
  color: ${colors.deepOrangeColor};
`;
const Textarea = styled.textarea`
  width: 100%;
  height: 150px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colors.lightGrayColor};

  &::placeholder {
    font-family: 'AppleSdNeoRegular';
    color: ${colors.graybColor};
  }
`;

const StyledTextarea = ({
                         REQUIRED,
                         label,
                         type = 'text',
                         name,
                         placeholder,
                         value,
                         onChange,
                         maxLength,
                     }) => {
    return (
        <Wrapper>
            <Label>
                {label}&nbsp;
                {REQUIRED && <Star>*</Star>}
            </Label>
            <Textarea
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                maxLength={maxLength}
                placeholder={placeholder}
            />
        </Wrapper>
    );
};

export default memo(StyledTextarea);
