import React, {useCallback} from "react";
import styled, {css} from "styled-components";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import ms_logo from '../../../assets/icons/medical_logo.svg';
import colors from "../../../styles/colors";
import AnimatedHamburgerButton from "./AnimatedHamburgerButton";
import DropDownMenu from "./DropDownMenu";
import HamburgerMenuBox from "./HamburgerMenuBox";

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: ${colors.whiteColor};
`;
const Content = styled.header`
  width: 100%;
  height: 80px;
  position: relative;
  max-width: 1144px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
`;
const FlexBox = styled.div`
  display: flex;
  flex: ${({flex}) => flex ? flex : 1};

  &:nth-child(2) {
    justify-content: center;
  }
;

  &:nth-child(3) {
    justify-content: flex-end;
  }
;
`;
const Image = styled.img`
  width: 140px;
  cursor: pointer;
`;
const MenuList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const MenuItem = styled.li`
  height: 80px;
  padding: 32px 0;
  font-size: 15px;
  font-weight: bold;
  margin: 0 18px;
  position: relative;
  cursor: pointer;
`;
const TabMenuText = styled.span``;
const SlideBar = styled.div`
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 26px;
  background-color: ${colors.purpleColor};
  transition: .3s;

  ${MenuItem}:hover & {
    width: 100%;
  }

  ${({active}) => active && css`
    width: 100%;
  `}
`;
const DropDownBox = styled.div`
  width: 100vw;
  height: 0;
  padding-left: calc(100% / 3);
  display: flex;
  position: fixed;
  top: 80px;
  left: 0;
  z-index: 100;
  background-color: ${colors.purpleColor};
  overflow: hidden;
  transition: .3s;

  ${MenuItem}:hover & {
    height: 262px;
  }
`;

const Header = () => {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const onClickLogo = useCallback(() => navigate('/'), []);
    const onClickHamburger = useCallback(() => setIsMenuOpen(!isMenuOpen), [isMenuOpen]);

    return (
        <Wrapper>
            <Content>
                <FlexBox onClick={onClickLogo}>
                    <Image src={ms_logo}/>
                </FlexBox>
                <FlexBox flex={2}>
                    <MenuList>
                        <MenuItem>
                            <TabMenuText onClick={() => navigate('/about')}>회사소개</TabMenuText>
                            <SlideBar/>
                            <DropDownBox>
                                <DropDownMenu
                                    name='about'
                                />
                            </DropDownBox>
                        </MenuItem>
                        <MenuItem>
                            <TabMenuText onClick={() => navigate('/service')}>서비스</TabMenuText>
                            <SlideBar/>
                            <DropDownBox>
                                <DropDownMenu
                                    sideBorder
                                    name='service'
                                />
                                <DropDownMenu
                                    sideBorder
                                    name='serviceSecond'
                                />
                            </DropDownBox>
                        </MenuItem>
                        <MenuItem>
                            <TabMenuText onClick={() => navigate('/estimate')}>온라인견적</TabMenuText>
                            <SlideBar/>
                            <DropDownBox>
                                <DropDownMenu
                                    name='estimate'
                                />
                            </DropDownBox>
                        </MenuItem>
                        <MenuItem>
                            <TabMenuText onClick={() => navigate('/support')}>고객지원</TabMenuText>
                            <SlideBar/>
                            <DropDownBox>
                                <DropDownMenu
                                    name='serviceCenter'
                                />
                            </DropDownBox>
                        </MenuItem>
                    </MenuList>
                </FlexBox>
                <FlexBox>
                    <AnimatedHamburgerButton
                        isOpen={isMenuOpen}
                        onClick={onClickHamburger}
                    />
                </FlexBox>
            </Content>

            <HamburgerMenuBox
                isOpen={isMenuOpen}
            />
        </Wrapper>
    );
};
export default React.memo(Header);
