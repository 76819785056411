import React from "react";
import styled from "styled-components";
import colors from "../../../styles/colors";

const Wrapper = styled.div`
  min-width: 180px;
  height: 126px;
  padding: 10px 0 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${colors.whiteColor};
`;
const Icon = styled.img`
  margin-bottom: 10px;
`;
const Title = styled.div`
  width: 76%;
  text-align: center;
  line-height: 1.2;
`;

const EMRChart = ({
                    title = '',
                    src = ''
                     }) => {
    return (
        <Wrapper>
            <Icon src={src} />
            <Title>{title}</Title>
        </Wrapper>
    )
}

export default React.memo(EMRChart);
