import React, { useCallback, useContext } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import colors from '../../../styles/colors';
import { AppContext } from '../../../App';

const ItemBox = styled.ul`
  width: 216px;
  height: 100%;
  padding: 24px 0 24px 20px;

  ${({ sideBorder }) =>
    sideBorder &&
    css`
      border-left-width: 1px;
      border-right-width: 1px;
      border-style: solid;
      border-color: ${colors.purpleBorder};
    `};

  & + & {
    border-left: none;
  }
`;
const ItemList = styled.li`
  font-size: 14px;
  font-weight: 400;
  color: ${colors.whiteColor};
  margin-top: 14px;
  position: relative;
  cursor: pointer;

  &:nth-child(1) {
    margin-top: 2px;
  }
`;
const Text = styled.span`
  font-family: 'AppleSdNeoRegular';
  line-height: 1.2;
  position: relative;

  &::after {
    content: '';
    width: 0;
    height: 1px;
    left: 0;
    bottom: -3px;
    position: absolute;
    background-color: ${colors.whiteColor};
    transition: 0.3s;
  }

  ${ItemList}:hover &::after {
    width: 100%;
  }
`;

const DropDownMenu = ({ name = '', sideBorder = false }) => {
  const navigate = useNavigate();
  const { setServiceActiveTab } = useContext(AppContext);

  const menuList = {
    about: ['회사소개'],
    service: ['Hippocrates', 'Hippocrates Check', 'Hippocrates Image', 'Hippocrates LAB', 'Hippocrates e-Sign', 'Hippocrates Desk', 'Hippocrates Cloud Service'],
    serviceSecond: ['Hippocrates Web DID', 'Hippocrates Medical Checkup', 'Hippocrates Pen Chart', '라온제나 CRM', '기타서비스'],
    estimate: ['온라인 견적하기'],
    serviceCenter: ['공지사항', '문의하기', '고객센터'],
  };

  const onClickMenu = useCallback(
    (path) => {
      console.info(path);

      switch (path) {
        case '회사소개':
          navigate('/about');
          break;

        case 'Hippocrates':
          navigate('/service/detail/emr');
          break;
        case 'Hippocrates Check':
          navigate('/service/detail/check');
          break;
        case 'Hippocrates Image':
          navigate('/service/detail/image');
          break;
        case 'Hippocrates LAB':
          navigate('/service/detail/lab');
          break;
        case 'Hippocrates e-Sign':
          navigate('/service/detail/eSign');
          break;
        case 'Hippocrates Desk':
          navigate('/service/detail/desk');
          break;
        case 'Hippocrates Cloud Service':
          navigate('/service/detail/cloud');
          break;
        case 'Hippocrates Web DID':
          navigate('/service/detail/did');
          break;
        case 'Hippocrates Medical Checkup':
          navigate('/service/detail/checkUp');
          break;
        case 'Hippocrates Pen Chart':
          navigate('/service/detail/chart');
          break;
        case '라온제나 CRM':
          navigate('/service/detail/crm');
          break;
        case '기타서비스':
          navigate('/service/detail/etc');
          break;

        case '온라인 견적하기':
          navigate('/estimate');
          break;
        case '공지사항':
          setServiceActiveTab('공지사항');
          navigate('/support');
          break;
        case '문의하기':
          setServiceActiveTab('문의하기');
          navigate('/support');
          break;
        case '고객센터':
          setServiceActiveTab('고객센터');
          navigate('/support');
          break;
      }
    },
    [navigate]
  );

  return (
    <ItemBox sideBorder={sideBorder}>
      {menuList[name]?.map((list, index) => (
        <ItemList key={`${list}-${index}`} onClick={() => onClickMenu(list)}>
          <Text>{list}</Text>
        </ItemList>
      ))}
    </ItemBox>
  );
};

export default React.memo(DropDownMenu);
