import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_SERVER_URL,
  // uri: "https://medipf.platcube.info/graphql",
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink,
});
