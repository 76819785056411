import React from "react";
import styled, {css} from "styled-components";
import GuideLine from "./GuideLine";

const Wrapper = styled.div`
  
`;
const IntroTextBox = styled.div`
  max-width: 1144px;
  margin: 60px auto 40px;
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  
  ${({ CENTER }) => CENTER && css`
    align-items: center;
  `};
`;
const LargeText = styled.div`
  font-size: 32px;
  font-family: 'AppleSdNeoBold';
`;
const SmallText = styled.div`
  font-size: 18px;
  margin: 40px 0;
  font-family: 'AppleSdNeoRegular';

  ${({ CENTER }) => CENTER && css`
    text-align: center;
  `};
`;
const BannerImage = styled.div`
  height: 436px;
  margin-bottom: 80px;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const PageIntro = ({ CENTER, guideLine = [], title = '', subTitle = '', src }) => {
    return (
        <Wrapper>
            <GuideLine label={guideLine}/>

            <IntroTextBox CENTER={CENTER}>
                <LargeText>
                    {title.split('\n').map((text, index) => (
                        <span key={`text-${index}`}>
                            {text} <br/>
                        </span>
                    ))}
                </LargeText>
                <SmallText CENTER={CENTER}>
                    {subTitle.split('\n').map((text, index) => (
                        <span key={`text-${index}`}>
                            {text} <br/>
                        </span>
                    ))}
                </SmallText>
            </IntroTextBox>
            {src && (
                <BannerImage>
                    <Image src={src} />
                </BannerImage>
            )}
        </Wrapper>
    )
}

export default React.memo(PageIntro);
