import styled from 'styled-components';
import colors from '../../styles/colors';
import History from '../../components/feature/Home/History';
import Address from '../../components/feature/Home/Address';
import PageIntro from '../../components/layout/PageIntro';
import aboutImage from '../../assets/images/about_banner.jpeg';
import { useQuery } from '@apollo/client';
import { SEE_COMPANY_INFO } from '../../graphql/Home/query';
import { useEffect, useState } from 'react';

const Contents = styled.section`
  max-width: 1144px;
  margin: 60px auto 80px;
`;
const FlexBox = styled.div`
  display: flex;
  font-size: 18px;
`;
const Title = styled.div`
  width: 220px;
  font-size: 18px;
  line-height: 1.2;
`;
const WelcomeText = styled.div`
  width: 660px;
  font-size: 18px;
  font-family: 'AppleSdNeoRegular';
  word-break: keep-all;
  letter-spacing: 0.7px;
  line-height: 1.4;
  padding-bottom: 80px;
  margin-bottom: 80px;
  border-bottom: 1px solid ${colors.blackColor};
`;
const Paragraph = styled.p`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;
const InfoBox = styled.div`
  padding-bottom: 80px;
  margin-bottom: 80px;
  border-bottom: 1px solid ${colors.blackColor};

  & > ${FlexBox} {
    align-items: center;
    margin-bottom: 24px;
  }

  & > ${FlexBox}:last-child {
    margin-bottom: 0;
  }

  & ${Title} {
    width: 140px;
  }
`;
const Text = styled.div`
  font-family: 'AppleSdNeoRegular';
`;

const AboutPresenter = () => {
  const [imgData, setImgData] = useState('');
  const { data } = useQuery(SEE_COMPANY_INFO);

  useEffect(() => {
    if (data) {
      setImgData(data?.seeCompanyInfo?.hi_url);
    }
  }, [data]);

  return (
    <>
      <PageIntro
        guideLine={['홈', '회사 소개']}
        title={`30여년 오직 한길만 걸으며\n병원의 성공과 함께 해 온 메디칼 소프트`}
        subTitle="소프트웨어를 통해 고객이 꿈꾸는 가치를 실현합니다"
        src={imgData}
      />
      <Contents>
        <FlexBox>
          <Title>
            대표이사
            <br />
            인사말
          </Title>
          <WelcomeText>
            <Paragraph>
              ㈜MedicalSoft는 “소프트웨어를 통한 고객 가치 실현”이라는 경영이념을 바탕으로 고객의 가치를 최우선으로 여기며, 끊임없이 노력하여 고객 여러분과 함께 성장하는 기업이
              되겠습니다. 기업 가치는 고객 여러분의 지속적인 관심과 사랑에 의해 결정됩니다. 오늘의 ㈜MedicalSoft가 이 자리에 있도록 함께해주신 모든 분들께 진심으로 감사드리며,
              앞으로도 여러분 곁을 지킬 것을 약속 드립니다.
            </Paragraph>
            <Paragraph>
              의료 분야 소프트웨어 전문지식과 젊고 참신한 인재가 결합된 ㈜MedicalSoft는 고객 여러분의 가치 창조 및 실현을 위해 최선을 다할 것이며, 고객의 목소리에 늘 세심한 주의를
              기울이는 기 업으로서 책임과 의무를 다하겠습니다.
            </Paragraph>
            <Paragraph>
              또한, ㈜MedicalSoft는 전자 의무기록 (EMR) 및 다양한 서비스를 통해 구축된 의료 정보 서비스 시스템을 적극 활용하여 효율적이고 스마트한 병원 업무를 지원할 것입니다. 항상
              발전하는 ㈜MedicalSoft가 되어 고객 여러분의 믿음에 부응하는 기업이 될 것을 약속 드립니다.
            </Paragraph>
          </WelcomeText>
        </FlexBox>
        <FlexBox>
          <Title>회사정보</Title>
          <InfoBox>
            <FlexBox>
              <Title>설립연도</Title>
              <Text>1986년 설립</Text>
            </FlexBox>
            <FlexBox>
              <Title>본사 위치</Title>
              <Text>서울시 금천구 가산디지털2로 123 월드메르디앙2차 707호</Text>
            </FlexBox>
            <FlexBox>
              <Title>지점 위치</Title>
              <Text>1986년 설립</Text>
            </FlexBox>
            <FlexBox>
              <Title>주요 서비스</Title>
              <Text>히포크라테스 계열 병·의원 종합 플랫폼</Text>
            </FlexBox>
          </InfoBox>
        </FlexBox>

        <History />
        <Address />
      </Contents>
    </>
  );
};

export default AboutPresenter;
