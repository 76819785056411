import React from "react";
import styled from "styled-components";
import colors from "../../styles/colors";

const Wrapper = styled.div`
  width: 100%;
  padding-top: 30px;
  padding-bottom: 80px;
  border-top: 1px solid ${colors.grayColor};
`;
const Section = styled.section`
  width: 100%;
  max-width: 1144px;
  font-size: 15px;
  margin: 0 auto;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;
const Company = styled.div``;
const SmallText = styled.div`
  font-family: "AppleSdNeoRegular";
  white-space: normal;
  word-break: keep-all;
  margin-right: 40px;
`;
const RowBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const Footer = () => {
  return (
    <Wrapper>
      <Section>
        <Company>Copyright ⓒ Medical Soft | All right reserved.</Company>
        <RowBox>
          <SmallText>(주) 메디컬소프트</SmallText>
          <SmallText>
            서울시 금천구 가산디지털2로 123 월드메르디앙2차 707호
          </SmallText>
          <SmallText>TEL. 02-2025-4333 / FAX. 02-2025-4337</SmallText>
          <SmallText>사업자등록번호 106-86-02713</SmallText>
        </RowBox>
      </Section>
    </Wrapper>
  );
};

export default Footer;
