import styled from "styled-components";
import SlideBanner from "../../components/feature/Home/SlideBanner";
import ShortcutBox from "../../components/feature/Home/ShortcutBox";
import GirdImage from "../../components/feature/Home/GridImage";
import { IoChevronForwardOutline } from "react-icons/io5";
import History from "../../components/feature/Home/History";
import Address from "../../components/feature/Home/Address";
import { useNavigate } from "react-router-dom";

const Contents = styled.div`
  max-width: 1144px;
  margin: 0 auto;
`;
const ShortcutInfo = styled.div`
  height: 160px;
  display: flex;
  margin: 80px 0;
`;
const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
`;
const MoreViewBox = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  margin: 24px 0 80px;
  cursor: pointer;

  .arrow {
    margin-left: 10px;
  }
`;
const BoldText = styled.div`
  font-size: 32px;
  line-height: 1.2;
`;
const RegularText = styled.div`
  font-size: 18px;
  margin: 40px 0 120px;
`;

const HomePresenter = ({ GRID_IMAGES }) => {
  const navigate = useNavigate();

  return (
    <>
      <SlideBanner />
      <Contents>
        <ShortcutInfo>
          <ShortcutBox
            title="공지"
            linkText="공지 전체 보기"
            data={{
              subTitle:
                "코로나 19 관련 건강보험 수가 연장 코로나 19 관련 건강보험 수가 연장 ",
              subText:
                "아래 같이 코로나-19 건강보험 수가가 연장되었으니 참고하세요.",
            }}
            path="/support"
          />
          <ShortcutBox
            title="인사말"
            linkText="대표이사 인사말"
            data={{
              subTitle:
                "30여년 병원의 성공과 함께 해 온 메디칼소프트를 찾아주셔서 감사합니다.",
            }}
            path="/about"
          />
          <ShortcutBox
            title="서비스 소개"
            linkText="서비스 전체보기"
            data={{
              subTitle: "총 130여개의 서비스",
              date: "2023.04.18",
            }}
            path="/service"
          />
          <ShortcutBox
            title="견적문의"
            linkText="온라인 견적문의"
            data={{
              subTitle:
                "독보적인 경험을 바탕으로 한 효율적이고 스마트한 EMR 차트",
            }}
            path="/estimate"
          />
        </ShortcutInfo>

        <GridBox>
          {GRID_IMAGES.map((data) => (
            <GirdImage
              key={`img-${data.title}`}
              src={data.src}
              title={data.title}
              subTitle={data.subTitle}
              path={data.path}
            />
          ))}
        </GridBox>

        <MoreViewBox onClick={() => navigate("/service")}>
          솔루션 더보기
          <IoChevronForwardOutline className="arrow" fontSize={16} />
        </MoreViewBox>
        <BoldText>
          30여년 오직 한길만 걸으며
          <br />
          병원의 성공과 함께 해 온 메디칼소프트
        </BoldText>
        <RegularText>
          소프트웨어를 통해 고객이 꿈꾸는 가치를 실현합니다.
        </RegularText>
        <History />

        <Address />
      </Contents>
    </>
  );
};

export default HomePresenter;
